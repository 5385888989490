import React from 'react'
import "./Contacts.css"
const ContactsHead = () => {
  return (
    <div className='contactHead'>
      <h3>TALK TO US AND</h3>
      <h2>WE CAN <span>BUILD UR SPACE</span> AROUND YOU</h2>
    </div>
  )
}

export default ContactsHead
