import React from 'react'
import"./Testimonial.css"
const TestimonialHead = () => {
  return (
    <div className='testiHeadContainer'>
      <h2>CUSTOMER <span>TESTIMONIAL</span></h2>
    </div>
  )
}

export default TestimonialHead
