import React from "react";
import "./Faq.css";

const FaqHeader = () => {
  return (
    <div className="faqHeaderContainer">
      <h2>F<span>A</span>Q</h2>
    </div>
  );
};

export default FaqHeader;
