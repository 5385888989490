import React from 'react'
import HomeWhyHeader from '../../HomePage/HomeWhy/HomeWhyHeader'
import InteriorWhy from './InteriorWhy';

const InteriorWhy1 = () => {
  return (
    <div>
      <HomeWhyHeader/>
      <InteriorWhy/>
    </div>
  )
}

export default InteriorWhy1
