import React from 'react'
import "./Partners.css"
const BrandLogo = (props) => {
  return (
    <div className="brandIcons">
    <img src={props.logo} alt="" />
   </div>
  )
}

export default BrandLogo
