import React from "react";

const PartnersHead = () => {
  return (
    <div className="partnerHeadContainer">
      <h2>
        <span> BRANDS</span> WE USE
      </h2>
    </div>
  );
};

export default PartnersHead;
