import React from "react";
import "./Package.css";

const PackageHead = () => {
  return (
    <div className="packageHeadContainer">
      <h2>
        OUR <span>PACKAGES</span>
      </h2>
    </div>
  );
};

export default PackageHead;
