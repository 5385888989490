import React from "react";
import "./Portfolio.css";

const PortfolioHead = () => {
  return (
    <div className="portfolioHeadContainer">
      <h2>
        Providing Top-Quality Turnkey <span> Home Construction </span> Services
      </h2>
    </div>
  );
};

export default PortfolioHead;
