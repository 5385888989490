import React from "react";
import "./HomeAbout.css";

const AboutHead = () => {
  return (
    <div className="aboutHeadContainer">
      <h2>
        ABOUT <pre> </pre>
        <span>US</span>
      </h2>
    </div>
  );
};

export default AboutHead;
