import React from 'react'
import "./OurGoals.css"
const OurGoalHead = () => {
  return (
    <div className='ourGoalHead'>
      <h2>OUR <span>GOALS</span></h2>
    </div>
  )
}

export default OurGoalHead
