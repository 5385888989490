import React from "react";
import "./HomeWhy.css";
const HomeWhyHeader = () => {
  return (
    <div className="homeWhyHeadContainer">
      <h2>
        WHY CHOOSE <span>BUILD UR SPACE</span> ?
      </h2>
    </div>
  );
};

export default HomeWhyHeader;
