import React from "react";
import "./BrandWeUse.css";

const BrandWeUseHead = () => {
  return (
    <div className="brandWeUseHeadContainer">
      <h2>
        OUR <span>PARTNER</span>
      </h2>
    </div>
  );
};

export default BrandWeUseHead;
