import React from 'react'
import HomeHeaderImg from './HomeHeaderImg'
import "./HomeHeader.css"
const HomeHeader = () => {
  return (
    <div className='homeHeaderContainer'>
      <HomeHeaderImg/>
    </div>
  )
}

export default HomeHeader
